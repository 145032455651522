
import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'

@Component({
  layout: 'signin'
})
export default class SignInPage extends Vue {
  /**
   * *________________ Data ______________________
   */
  login = ''
  password = ''

  /**
   * *________________ Created ______________________
   */
  created () {
    this.$wait.end('leaveRouter')
  }

  /**
   * *________________ Methods ______________________
   */
  async signIn () {
    try {
      this.$wait.start('signIn')
      if (this.login.length <= 0) {
        // @ts-ignore
        this.$refs.login.validateState = 'error'
        // @ts-ignore
        this.$refs.login.validateMessage = 'Введите логин'
        return
        // @ts-ignore
      } else { this.$refs.login.validateState = 'success' }
      if (this.password.length <= 0) {
        // @ts-ignore
        this.$refs.password.validateState = 'error'
        // @ts-ignore
        this.$refs.password.validateMessage = 'Введите пароль'
        return
        // @ts-ignore
      } else { this.$refs.password.validateState = 'success' }
      await this.$auth.signin({
        login: this.login,
        password: this.password
      })
      await this.$auth.getProfile()
      window.location.assign(this.$cookies.get('prevLink') || '/')
      this.$cookies.remove('prevLink')
      this.$notify({
        title: 'Успешный вход в систему',
        message: 'Добро пожаловать',
        type: 'success'
      })
    } catch (e: any) {
      if (e.error_message === 'POST /login No such user.') {
        // @ts-ignore
        this.$refs.login.validateState = 'error'
        // @ts-ignore
        this.$refs.login.validateMessage = 'Пользователя не существует'
      } else if (e.error_message === 'POST /login Invalid password.') {
        // @ts-ignore
        this.$refs.password.validateState = 'error'
        // @ts-ignore
        this.$refs.password.validateMessage = 'Неправильный пароль'
      } else {
        this.$notify({
          title: e.error_code,
          message: e.error_message,
          type: 'error'
        })
      }
      this.$wait.end('signIn')
    }
  }
}
